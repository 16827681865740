<template>
    <div v-if="showModal" :class="['main-category-select-modal modal',showModal?'modal-open':'']" style="display: block">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <h3 class="modal-content__title">Please choose a category</h3>
                <p class="modal-content__info">So you can find exactly the right offers.</p>

                <div class="main-cats categories">
                    <div class="row">
                        <div class="col-12">
                            <button class="no-btn" @click="selectedOption(options[0])">
                                <div class="card bg-jade">
                                    <div class="card__img">
                                        <img src="/assets/icons/beruf.png" alt="Training &amp; further education">
                                    </div>
                                    <div class="card__content">
                                        <h3>Training &amp; further education</h3>
                                        <p>Find professional training &amp; further education offers.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="no-btn" @click="selectedOption(options[1])">
                                <div class="card bg-meganta">
                                    <div class="card__img">
                                        <img src="/assets/icons/hilfe.png" alt="Advice &amp; help">
                                    </div>
                                    <div class="card__content">
                                        <h3>Advice &amp; help</h3>
                                        <p>Find support in all situations.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                        <div class="col-12">
                            <button class="no-btn" @click="selectedOption(options[2])">
                                <div class="card bg-blue">
                                    <div class="card__img">
                                        <img src="/assets/icons/freizeit.png" alt="Leisure time">
                                    </div>
                                    <div class="card__content">
                                        <h3>Leisure time</h3>
                                        <p>Find ideas for your leisure activities.</p>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>